/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { constant } from "../api/ApiConstant";
import axios from "../api/axios";
import BackButton from "../components/BackButton";
import ConfirmModal from "../components/ConfirmationModal";
import {
  removeFailure,
  removeSuccess,
  setFailure,
  setSuccess,
} from "../store/actions/notify.actions";
import { changePageName } from "../store/actions/page.actions";
import { selectUser } from "../store/reducers/auth.reducers";
import "./Withdraw.css";

const TransferWallet = () => {

  const navigate =useHistory();

  useEffect (()=>{
    navigate.push('transfer-wallet');

  },[0])

  const user = useSelector((state) => selectUser(state));
  console.log("RefID", user?.refId);
  
  const [withdraw, setWithdraw] = useState({
    currencyType: "Transfer",
    recieverId: "",
    amount: "",
  });
  const [deduction, setDeduction] = useState({
    fc: "",
    amount: "",
  });
  const [stacking, setStacking] = useState({
    stackingWalletFC: "",
    stackingWalletRupee: "",
  });

  const [transferWallet, setTransferWallet] = useState();
  const [tokenAddress, setTokenAddress] = useState("");
  const [bankDetails, setBankDetails] = useState("");

  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const [initialLoading, setInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dollars, setDollars] = useState(true);
  const [checked, setChecked] = useState("");





  const handleChange = (e) => {
    const { name, value } = e.target;
    setWithdraw({ ...withdraw, [name]: name === "amount" ? +value : value });
    if (value === "Transfer") {
      setDollars(true);
    } else if (value === "Stacking") {
      setDollars(false);
    }
  };

  console.log(user, "see if name coming");

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };


  const Dollar = Math.round(stacking.stackingWalletRupee)

  useEffect (() => {
    handleSubmit()
  }, [])

    const handleSubmit = async (e) => {
      try {
      e.preventDefault();
      handleModal();
      setIsLoading(true);
      Balance();
      dispatch(removeFailure());

    //  if (withdraw.currencyType === "Transfer" || withdraw.currencyType === "Stacking"){
    //   return dispatch(
    //   setFailure("Please try after somtime")
    //   )
    //  }
    // console.log("hi",tokenAddress);
    //   if (!tokenAddress)
    //     return dispatch(
    //       setFailure("Please set free coin address before withdraw")
    //     );
      const response2 = await axios.get(
        `${constant.dashboard.totalStacking}/${user?.id}`
      );
      console.log("response2.data", response2.data);

      setTransferWallet(response2.data.data.availableBalance);
      if (
        (withdraw.currencyType === "Transfer" && withdraw.amount < 1) ||
        (withdraw.currencyType === "Stacking" && withdraw.amount < 1) ||
        (withdraw.currencyType === "Transfer" &&
          withdraw.amount > transferWallet) ||
        (withdraw.currencyType === "Stacking" &&
          withdraw.amount > Dollar) ||
        withdraw.amount <= 0
      ) {
        setIsLoading(false);
        return dispatch(setFailure("Please enter valid amount"));
      }
      if (user.refId === withdraw.recieverId){
        return dispatch(setFailure("Enter another user ID"))
      }
      if (!otpSent) {
        const response = await axios.post(
          `${constant.otp.send}/${user.id}`,
          {}
        );
        setOtpSent(response.data);
        dispatch(setSuccess("OTP sent to your email. Please check it"));
        setTimeout(() => dispatch(removeSuccess()), 1000);
        setIsLoading(false);
        return;
      }
      if (!otp) {
        setIsLoading(false);
        return dispatch(
          setFailure("Please enter corresponding otp to your email")
          );
        }
        const result = await axios.get(
          `${constant.otp.verify}/${user.id}/${otp}`
      );
      if (!result.data.data) {
        setIsLoading(false);
        return dispatch(setFailure("Please enter valid OTP"));
      }
      let values = {};
      if (withdraw.currencyType === "Transfer") {
        values = {
          ...values,
          fromRefId: user?.refId,
          // toId: withdraw.ID,
          toRefId: withdraw.recieverId,
          amount: withdraw.amount,
        };
      } else if (withdraw.currencyType === "Stacking") {
        values = {
          ...values,
          userId: user?.id,
          amount: withdraw.amount,
          refId : user?.refId,
          // proofImageUrl:
          //   "https://www.google.com/images/branding/googlelogo/2x/googlelogo_light_color_272x92dp.png",
          };
        }
        console.log("dafdafaf", withdraw.currencyType);
        console.log("dffffffff", values);
          if (withdraw.currencyType === "Transfer") {
            console.log("cominggg", withdraw.currencyType, "677lklgtc", values  );
            const response = await axios.post(constant.internalTransfer, values);
            console.log("ressssss", response);
            if (response.data) {
              dispatch(setSuccess("Transfer Request sent"));
              Balance();
              setTimeout(() => {
                dispatch(removeSuccess());
                
              }, 3000);
              setWithdraw({
                currencyType: "Transfer",
                recieverId: "",
                amount: "",
              });
              setOtp("");
              setOtpSent(false);
            }
          } else if (withdraw.currencyType === "Stacking") {
            console.log("elseeee", values);
            const response = await axios.post(constant.wallet.stackingTopup, values);
            console.log("values+Resss", values, "RRR", response); 
            if(response.data){
              dispatch(setSuccess("Topup Request sent"));
              Balance();
              setTimeout(() => {
                dispatch(removeSuccess());
                }, 3000);          
                
                setWithdraw({
                  currencyType: "Stacking",
                  recieverId: "",
                  amount: "",
                });
                Balance();
                setOtp("");
                setOtpSent(false);
                setIsLoading(false)
              }

                // const reqData = {
                //   ...response.data.data,
                //   status: "A",
                //   respondedAt: new Date(),
                // };
                // const res = await axios.put(constant.wallet.stackingAccept, reqData);
                
                // console.log("resssssStacking", res);
            }
          } catch (err) {
            console.error(err.message);
          } finally {
            setIsLoading(false);
          }  
        };
        
console.log(withdraw);

  useEffect(() => {
    Balance();
  }, []);
  
  const Balance = async () => {
    try {
      const response2 = await axios.get(
        `${constant.dashboard.totalStacking}/${user?.id}`
        );
        console.log("*****", response2);
      const availableBalance = response2.data.data.availableBalance;

      setTransferWallet(availableBalance);
      const result = await axios.get(
        `${constant.conversion.coinToUsd}/${availableBalance}`
        );
        console.log("#####", result);
        setStacking({
          ...stacking,
          stackingWalletFC: availableBalance,
          stackingWalletRupee: result.data.data,
        });
      } catch (err) {
        console.log(err);
      }
    };
    const dispatch = useDispatch();
    
    useEffect(() => {
      dispatch(changePageName("Transfer Wallet"));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
      const fetchBankAddress = async () => {
        try {
          const response = await axios.get(`${constant.bank.fetch}/${user.id}`);
          if (response.status === 200) setBankDetails(response.data.data);
        } catch (error) {
          console.error(error.message);
        }
      };
      fetchBankAddress();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
      const fetchCoinAddress = async () => {
        try {
          const response = await axios.get(`/user/getFreeCoinAddress/${user.id}`);
          if (response.data.data)
          setTokenAddress(response.data.data.freeCoinAddress);
        } catch (err) {
          console.log(err);
        }
      };
      fetchCoinAddress();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
      const getWalletValues = async () => {
        try {
          const { data } = await axios.get(
            `${constant.dashboard.wallet}/${user?.id}`
            );
            // const totalStacking =
            //   data.data.stackingWalletAmountNew +
        //   data.data.offerWalletAmountNew +
        //   data.data.earningWalletAmountNew;
        
        // const result = await axios.get(
        //   `${constant.conversion.coinToUsd}/${totalStacking}`
        // );
        // setStacking({
          //   ...stacking,
          //   stackingWalletFC: totalStacking,
          //   stackingWalletRupee: result.data.data,
          // });
          
          const response = await axios.get(
            `/user/getDeductionAmount/${user?.id}`
            );
            
            setDeduction(response.data.data);
          } catch (err) {
            console.log(err);
          } finally {
            if (initialLoading) setInitialLoading(false);
          }
        };
        getWalletValues();
      }, []);


      const checkName = async () => {
        const response = await axios.get(
          `/user${constant.checkUserName}/${withdraw?.recieverId}`, 
        ).then(resp => {
          setChecked(resp?.data)
         })
        console.log(checked, "api response");
      }
      
      if (initialLoading) return <p style={{ color: "black" }}>Loading...</p>;
      
      return (
        <>
      {console.log("dafdafaf", withdraw.currencyType)}

      <BackButton />
      <div className="card-container">
        <div className="withdraw-top">
          <div className="balance-container">
            <div className="balance">
              <h4 className="balance-title">
                Available Balance in Transfer Wallet
              </h4>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="balance-amount dark__blueText">
                  <b>Coins :{Math.round(transferWallet) || "0"}</b>
                </h2>
                <h2 className="balance-amount dark__blueText">
                  <b>$ : {Math.round(stacking.stackingWalletRupee) || "0"}</b>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="withdraw-bottom">
          <div>
            <div className="withdraw-formGroup">
              <label className="withdraw-formLabel">Currency Type</label>
              <select
                value={withdraw.currencyType}
                onChange={handleChange}
                name="currencyType"
                className="withdraw-select"
                style={{ width: "100%" }}
              >
                <option value="Transfer">Transfer</option>
                <option value="Stacking">Staking</option>
              </select>
            </div>
            <div className="withdraw-formGroup">
              {withdraw.currencyType === "Stacking" ? (
                <></>
              ) : (
                <>
                  <label className="withdraw-formLabel">User ID</label>
                  <input
                    type="text"
                    placeholder="Enter Beneficier ID"
                    className="withdraw-formInput"
                    style={{ marginBottom: "10px" }}
                    name="recieverId"
                    value={withdraw.recieverId}
                    onChange={handleChange}
                  />
                  <div className="check-container">
                    <button type="submit" className="check-button"
                    onClick={checkName}>Check</button>
                    <p className="check-name">{checked?.data}</p>
                  </div>
                </>
              )}
              <label className="withdraw-formLabel">
                {dollars ? "Coin (FMDC)" : "$"}{" "}
              </label>
              {dollars ? (
                <input
                  type="number"
                  placeholder="Enter Your Amount"
                  className="withdraw-formInput"
                  value={withdraw.amount}
                  name="amount"
                  min={1}
                  onChange={handleChange}
                />
              ) : (
                <select
                  type="number"
                  placeholder="Enter Your Amount"
                  value={withdraw.amount}
                  name="amount"
                  min={1}
                  onChange={handleChange}
                  className="withdraw-formSelect"
                >
                  <option value={""}>Select Dollars</option>
                  <option value={25}>$25</option>
                  <option value={50}>$50</option>
                  <option value={75}>$75</option>
                  <option value={100}>$100</option>
                  <option value={125}>$125</option>
                  <option value={150}>$150</option>
                  <option value={175}>$175</option>
                  <option value={200}>$200</option>
              </select> 
              )}
            </div>
            {otpSent && (
              <div className="withdraw-formGroup">
                <label className="withdraw-formLabel">OTP</label>
                <input
                  type="text"
                  className="withdraw-formInput"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
            )}
          </div>
          <button
            type="button"
            onClick={handleModal}
            style={{ backgroundColor: "#4530B2" }}
            className="withdraw-button dark__blue"
            disabled={!stacking.stackingWalletFC || isLoading}
          >
            {isLoading ? "Loading" : "Submit"}
          </button>
        </div>
        <p className="notes">
          Notes: <br />
          {/* 1.Minimum Withdrawal in COINS is 50,00,000 ( Fifty Lakhs )<br /> */}
          {/* 2.Transaction Fees for Coin Withdrawal is 2,00,000 ( Two Lakhs )<br /> */}
          {/* 3.Minimum Withdrawal in RUPEES is 500/- ( Five Hundred )<br />
          4.Transaction Fees for Rupees Withdrawal is 50/- ( Fifty )<br /> */}
          1.The Coin Withdrawal takes 48 Hours to success
          <br />
          {/* 6.The Rupee Withdrawal takes 48 Hours to success */}
          <br />
        </p>
      </div>
      <ConfirmModal visible={isModalOpen} yes={handleSubmit} no={handleModal} />
    </>
  );
};





 
// const TransferWallet = () => {

//   const [block, setBlock] = useState(false);


//   if(user?.refId === "FM5073953"){
//     console.log("jhiii");
//     setBlock(true)
//   } else {
//     console.log("hello");
//   }


//   return (
//     <>
//     { block ? 
//       <div className='issue-tw'>
//       <p className='issue-text-tw'>Sorry for the inconvenience. We&rsquo;re performing some maintanence in this page. We&rsquo;ll be back today</p>
//       </div> :
//       <div className='issue-tw'>
//       <p className='issue-text-tw'>Sorry for the inconvenience. We&rsquo;re performing some maintanence in this page. We&rsquo;ll be back today</p>
//       </div>
//     }
//     </>
//   )
// }

export default TransferWallet;